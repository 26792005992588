/**
 * Environment settings for production
 */
const gqlAddress = '/api/v2/gql/';

export const environment = {
  hmr: false,
  production: true,
  native: false,
  graphqlApi: {
    productsApi: gqlAddress,
    baskets: gqlAddress,
    accounts: gqlAddress,
  },
  productListApi: '/api/v1/productlist',
  merchandiseApi: '/api/merchandising',
  availableLanguagesApiUrl: '/api/translations/languages',
  sizeRunsApiUrl: '/api/v2/recommendation/sizeRuns',
  suggestApiUrl: '/api/v2/recommendation/suggest',
  sharedLinkApi: '/api/sharedlink',
  contentApi: '/api/v2/content',
  // TODO remove legacy api endpoint once proxy is there
  sites: '/api/sites',
  analyticsApi: {
    azure: '/api/analytics/azure',
  },
  legacyContentApi: '/api/content',
  ruleEngineV1ApiUrl: '/api/rules',
  ruleEngineV2ApiUrl: '/api/v2/rules',
  youtubeApiUrl: 'https://www.youtube.com/iframe_api',
  usersApiUrl: '/api/users',
  accountsApiUrl: '/api/accounts',
  documentsApiUrl: '/api/documents',
  brandorganisationsApiUrl: '/api/v1/brandorganisations',
  basketApiUrl: '/api/baskets',
  basketV1ApiUrl: '/api/v1/basket',
  connectApiUrl: '/api/v2/connect',
  identityApiUrl: '/api/v2/identity',
  productsApiUrl: '/api/products',
  pricesApiUrl: '/api/prices',
  replenishmentsApiUrl: '/api/v2/recommendation/cmi/replenishment-list',
  engagementApiUrl: '/api/v2/engagement',
  inventoriesApiUrl: '/api/inventories',
  appName: 'pulse',
  /** A way of theming for icons is provided by svg sprites, this is the configuration for their paths */
  spritesPaths: {
    /** Located in the container `next/images/icons` on azure blob storage */
    PRODUCT: 'static/images/icons/product-sprites.svg',
    FILTER: 'static/images/icons/filter-sprites.svg',
  },
  ordersApiUrl: '/api/orders',
  ordersV2ApiUrl: '/api/v2/orders',
  aggregationsV1ApiUrl: '/api/v1/aggregations',
  reportingApi: {
    reportingApiUrl: '/api/v2/reporting',
    token: '/api/v2/reporting/token',
  },
  documentControlApiUrl: '/api/v2/document-control',
  budget: '/api/v2/reporting/budgets',
  version: 'version.json',
  basketReminder: '/api/v2/reporting/basket-reminder',
  pockets: '/api/v2/reporting/pockets',
  userFeedback: '/api/v2/reporting/user-feedback',
  mediaRelationsApiUrl: '/api/v2/products/media/relations',
};

import {
  defaultProductImageMissingVisualConfig,
  defaultRecommendationsPanelsConfig,
  ProductImageMissingVisualConfig,
  PropertyPathSvgReplacement,
  pulseConfigurationDefaultValues,
  PulseConfigurationKeys,
  PulseConfigurations,
  PULSE_FEATURE_NAME,
} from '@epicuro-next/constants/rule-engine/pulse-rules';
import {
  RecommendationsPanels,
  RecommendationsPanelsConfig,
} from '@epicuro-next/models/recommendation-panels';
import { DefaultMapFunctions } from '@epicuro-next/platform/tracking';
import { isNumber, isString } from '@epicuro-next/utilities';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { BasketNotificationStyle } from 'libs/constants/rule-engine/pulse-rules/src/lib/types/basket-notification-style';

import {
  selectConfigurationsState,
  selectConfigurationValueFrom,
} from './configuration.selectors';

export const selectIsAccountAddressReverted = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.AccountAddressReverted,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.AccountAddressReverted,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.AccountAddressReverted
    ],
  ),
  Boolean,
);

export const selectDiscountFormatRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.discountFormat,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.discountFormat,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.discountFormat],
) as MemoizedSelector<object, string>;

export const selectShowMaterialWidthIds = selectConfigurationValueFrom<
  PulseConfigurationKeys.ShowMaterialWidthIds,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ShowMaterialWidthIds,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.ShowMaterialWidthIds],
) as MemoizedSelector<object, boolean>;

export const selectEnableUseragreementButtonsAtEOD =
  selectConfigurationValueFrom<
    PulseConfigurationKeys.EnableUseragreementButtonsAtEOD,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.EnableUseragreementButtonsAtEOD,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.EnableUseragreementButtonsAtEOD
    ],
  ) as MemoizedSelector<object, boolean>;

export const selectTermsConditionsUserconsent = selectConfigurationValueFrom<
  PulseConfigurationKeys.TermsConditionsUserconsent,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.TermsConditionsUserconsent,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.TermsConditionsUserconsent
  ],
) as MemoizedSelector<object, boolean>;

export const selectIsSearchV2Enabled = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.EnableSearchV2,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.EnableSearchV2,
    pulseConfigurationDefaultValues[PulseConfigurationKeys.EnableSearchV2],
  ),
  (isRuleEnabled) => {
    const testUseKey = localStorage.getItem('useV2Search') === 'true';
    return !!isRuleEnabled || testUseKey;
  },
);

export const selectIsCatalogOnlyModeRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.EnableCatalogOnlyMode,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.EnableCatalogOnlyMode,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.EnableCatalogOnlyMode],
);

export const selectIsHorizontalViewRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.EnableHorizontalView,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.EnableHorizontalView,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.EnableHorizontalView],
);

export const selectIsFiltersVisibleRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.EnableFiltersVisible,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.EnableFiltersVisible,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.EnableFiltersVisible],
);

export const selectShowWspDefaultValueRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.ShowWholeSalePricesSettingsDefaultValue,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ShowWholeSalePricesSettingsDefaultValue,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.ShowWholeSalePricesSettingsDefaultValue
  ],
);

export const eventTrackMappingRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.EventTrackMapping,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.EventTrackMapping,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.EventTrackMapping],
);

export const selectShowAccountInHeader = selectConfigurationValueFrom<
  PulseConfigurationKeys.ShowAccountInHeader,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ShowAccountInHeader,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.ShowAccountInHeader],
);

export const selectShowAccountTooltipInHeader = selectConfigurationValueFrom<
  PulseConfigurationKeys.ShowAccountTooltipInHeader,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ShowAccountTooltipInHeader,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.ShowAccountTooltipInHeader
  ],
);

export const selectUpdateTitleOptionsRule = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.UpdateTitleOptions,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.UpdateTitleOptions,
    pulseConfigurationDefaultValues[PulseConfigurationKeys.UpdateTitleOptions],
  ),
  (configuration) => {
    try {
      return JSON.parse(configuration as string);
    } catch {
      return pulseConfigurationDefaultValues[
        PulseConfigurationKeys.UpdateTitleOptions
      ];
    }
  },
);

export const eventTrackingMappingConfiguration = createSelector(
  eventTrackMappingRule,
  (configuration) =>
    JSON.parse(
      configuration ??
        pulseConfigurationDefaultValues[
          PulseConfigurationKeys.EventTrackMapping
        ],
    ) as {
      [actionName: string]: { name: string; eventMappingFunction: string };
    },
);

export const eventTrackMapper = (name: string, type: string) =>
  createSelector(
    selectConfigurationsState,
    (state) =>
      (state[PULSE_FEATURE_NAME]?.[`${type}-${name}-track-mapper`] as string) ??
      // @ts-ignore
      DefaultMapFunctions[name],
  );

export const selectContactsUserMenu = selectConfigurationValueFrom<
  PulseConfigurationKeys.ContactsUserMenu,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ContactsUserMenu,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.ContactsUserMenu],
);

export const selectUseInternalIds = selectConfigurationValueFrom<
  PulseConfigurationKeys.GTMEcommerceUseInternalIds,
  PulseConfigurations
>(PULSE_FEATURE_NAME, PulseConfigurationKeys.GTMEcommerceUseInternalIds, false);

export const selectEnableOrderBookV2 = selectConfigurationValueFrom<
  PulseConfigurationKeys.EnableOrderBookV2,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.EnableOrderBookV2,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.EnableOrderBookV2],
) as MemoizedSelector<object, boolean>;

export const selectInvoicesPageEnabledRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.InvoicesPageEnabled,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.InvoicesPageEnabled,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.InvoicesPageEnabled],
) as MemoizedSelector<object, boolean>;

export const selectHighlightFutureTypeLinesEnabled = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.HighlightFutureTypeLinesEnabled,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.HighlightFutureTypeLinesEnabled,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.HighlightFutureTypeLinesEnabled
    ],
  ),
  Boolean,
);

export const selectPreventVisualSearchInEdge = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.PreventVisualSearchInEdge,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.PreventVisualSearchInEdge,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.PreventVisualSearchInEdge
    ],
  ),
  (value) => {
    return Boolean(value);
  },
);

export const selectProductImageMissingVisual = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.ProductImageMissingVisual,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.ProductImageMissingVisual,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.ProductImageMissingVisual
    ],
  ),
  (setting) => {
    try {
      if (!setting) {
        return defaultProductImageMissingVisualConfig;
      }

      const configFromString = JSON.parse(
        setting,
      ) as Partial<ProductImageMissingVisualConfig>;

      const enabledKey: keyof ProductImageMissingVisualConfig = 'enabled';
      const enabled =
        enabledKey in configFromString
          ? Boolean(configFromString.enabled)
          : defaultProductImageMissingVisualConfig.enabled;

      const replacementInstructionsKey: keyof ProductImageMissingVisualConfig =
        'replacementInstructions';
      const propertyPathKey: keyof PropertyPathSvgReplacement = 'propertyPath';
      const propertyFindgKey: keyof PropertyPathSvgReplacement = 'findInSvg';
      const replacementInstructions =
        replacementInstructionsKey in configFromString &&
        Array.isArray(configFromString.replacementInstructions)
          ? configFromString.replacementInstructions.filter(
              (ri) =>
                propertyPathKey in ri &&
                isString(ri.propertyPath) &&
                propertyFindgKey in ri &&
                isString(ri.findInSvg),
            )
          : defaultProductImageMissingVisualConfig.replacementInstructions;

      const svgKey: keyof ProductImageMissingVisualConfig = 'svg';
      const svg =
        svgKey in configFromString && isString(configFromString.svg)
          ? configFromString.svg
          : defaultProductImageMissingVisualConfig.svg;

      const thumbnailZoomFactorKey: keyof ProductImageMissingVisualConfig =
        'thumbnailZoomFactor';
      const thumbnailZoomFactor =
        thumbnailZoomFactorKey in configFromString &&
        (isNumber(configFromString.thumbnailZoomFactor) ||
          (isString(configFromString.thumbnailZoomFactor) &&
            configFromString.thumbnailZoomFactor))
          ? parseFloat(
              (configFromString.thumbnailZoomFactor as number | string)
                .toString()
                .trim(),
            )
          : defaultProductImageMissingVisualConfig.thumbnailZoomFactor;

      const config: ProductImageMissingVisualConfig = {
        enabled,
        replacementInstructions,
        svg,
        thumbnailZoomFactor,
      };

      return config;
    } catch (e) {
      console.warn(
        `Invalid value found for rule '${PulseConfigurationKeys.ProductImageMissingVisual}', falling back to default settings`,
      );

      return defaultProductImageMissingVisualConfig;
    }
  },
);

export const selectRecommendationsPanelsRule = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.RecommendationsPanels,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.RecommendationsPanels,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.RecommendationsPanels
    ],
  ),
  (recommendationsPanelsConfigValue: unknown): RecommendationsPanelsConfig => {
    const defaultConfig = JSON.parse(
      JSON.stringify(defaultRecommendationsPanelsConfig),
    ) as RecommendationsPanelsConfig;

    try {
      if (!isString(recommendationsPanelsConfigValue)) {
        console.warn(
          `Invalid type (${typeof recommendationsPanelsConfigValue}) used to define rule '${
            PulseConfigurationKeys.RecommendationsPanels
          }', falling back to default settings`,
        );

        return defaultConfig;
      }

      const config = {
        ...defaultConfig,
      };
      const objFromConfig = JSON.parse(recommendationsPanelsConfigValue);

      if (typeof objFromConfig === 'object' && objFromConfig !== null) {
        const keysFromConfig = Object.keys(objFromConfig);
        const validKeys = Object.keys(config);
        for (const key of keysFromConfig) {
          const validKey = validKeys.find(
            (vk) => vk.toLowerCase() === key.toLowerCase(),
          ) as keyof typeof RecommendationsPanels | undefined;

          if (validKey === undefined) {
            console.warn(`${key} is not a valid key for RecommendationsPanels`);
          } else {
            config[validKey] = objFromConfig[key] ?? [];
          }
        }

        return config;
      }
    } catch (e) {
      console.warn(
        `Invalid value found for rule '${PulseConfigurationKeys.RecommendationsPanels}', falling back to default settings`,
      );

      return defaultConfig;
    }

    return defaultConfig;
  },
);

export const selectPowerListEnabled = selectConfigurationValueFrom<
  PulseConfigurationKeys.PowerListEnabled,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.PowerListEnabled,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.PowerListEnabled],
);

export const selectRecommendationSlotsFromMerchandiser =
  selectConfigurationValueFrom<
    PulseConfigurationKeys.RecommendationSlotsFromMerchandiser,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.RecommendationSlotsFromMerchandiser,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.RecommendationSlotsFromMerchandiser
    ],
  );

export const selectUseHeartIconForBookmark = selectConfigurationValueFrom<
  PulseConfigurationKeys.UseHeartIconForBookmark,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.UseHeartIconForBookmark,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.UseHeartIconForBookmark
  ],
);

export const selectDisabledShareBrowserApi = selectConfigurationValueFrom<
  PulseConfigurationKeys.DisabledShareBrowserApi,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.DisabledShareBrowserApi,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.DisabledShareBrowserApi
  ],
);

export const selectBookmarkIcon = createSelector(
  selectUseHeartIconForBookmark,
  (useHeart) => {
    return useHeart ? ['far', 'heart'] : ['far', 'bookmark'];
  },
);

export const selectBookmarkedIcon = createSelector(
  selectUseHeartIconForBookmark,
  (useHeart) => {
    return useHeart ? ['fas', 'heart'] : ['fas', 'bookmark'];
  },
);

export const selectBasketNotificationStyle = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.BasketNotificationStyle,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.BasketNotificationStyle,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.BasketNotificationStyle
    ],
  ),
  (basketNotificationStyle) => {
    const allValidStyles = Object.values(BasketNotificationStyle).map(
      (v) => v as string,
    );

    if (basketNotificationStyle) {
      const value = basketNotificationStyle.trim().toLowerCase();
      if (allValidStyles.includes(value)) {
        return value;
      }
    }

    console.warn(
      `BasketNotificationStyle '${basketNotificationStyle}' is not a valid value. Valid values are: ${allValidStyles.join(
        ', ',
      )}. Falling back to '${BasketNotificationStyle.default}'.`,
    );

    return BasketNotificationStyle.default;
  },
);

export const selectGcrNormalizePriceForPurchaseEvent =
  selectConfigurationValueFrom<
    PulseConfigurationKeys.GcrNormalizePriceForPurchaseEvent,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.GcrNormalizePriceForPurchaseEvent,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.GcrNormalizePriceForPurchaseEvent
    ],
  );

export const selectAttributesPriorityConfig = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.AttributesPriorityConfig,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.AttributesPriorityConfig,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.AttributesPriorityConfig
    ],
  ),
  (config) => {
    try {
      return JSON.parse(config as string);
    } catch (e) {
      console.error(`Failed provided rule attributes-priority-config: ${e}`);
      return JSON.parse(
        pulseConfigurationDefaultValues[
          PulseConfigurationKeys.AttributesPriorityConfig
        ],
      );
    }
  },
);

export const selectUserMenuBadgesConfig = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.UserMenuItemsBadges,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.UserMenuItemsBadges,
    pulseConfigurationDefaultValues[PulseConfigurationKeys.UserMenuItemsBadges],
  ),
  (config) => {
    try {
      return JSON.parse(config as string);
    } catch (e) {
      console.error(
        `Failed provided rule user-menu-items-badges: ${e}. Use format: { "e2eKey": { "type": "danger", "text": "new" } }`,
      );
      return JSON.parse(
        pulseConfigurationDefaultValues[
          PulseConfigurationKeys.UserMenuItemsBadges
        ],
      );
    }
  },
);

export const selectQuickSearchDropdown = selectConfigurationValueFrom<
  PulseConfigurationKeys.QuickSearchDropdown,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.QuickSearchDropdown,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.QuickSearchDropdown],
);

export const selectSearchSuggestEnabled = selectConfigurationValueFrom<
  PulseConfigurationKeys.SearchSuggestEnabled,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.SearchSuggestEnabled,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.SearchSuggestEnabled],
);

export const selectShowMarkupEnabled = selectConfigurationValueFrom<
  PulseConfigurationKeys.ShowMarkup,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ShowMarkup,
  pulseConfigurationDefaultValues[PulseConfigurationKeys.ShowMarkup],
);

export const selectSizePreviewV2Enabled = createSelector(
  selectConfigurationValueFrom<
    PulseConfigurationKeys.SizePreviewV2Enabled,
    PulseConfigurations
  >(
    PULSE_FEATURE_NAME,
    PulseConfigurationKeys.SizePreviewV2Enabled,
    pulseConfigurationDefaultValues[
      PulseConfigurationKeys.SizePreviewV2Enabled
    ],
  ),
  Boolean,
);

export const selectProductIdAdditionalPrefixRule = selectConfigurationValueFrom<
  PulseConfigurationKeys.ProductIdAdditionalPrefix,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ProductIdAdditionalPrefix,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.ProductIdAdditionalPrefix
  ],
);

export const selectProductIdAdditionalPrefixRexExp = createSelector(
  selectProductIdAdditionalPrefixRule,
  (additionalPrefix) => new RegExp(additionalPrefix ?? '', 'gi'),
);

export const selectToggleDiscountLabelByWsp = selectConfigurationValueFrom<
  PulseConfigurationKeys.ToggleDiscountLabelByWsp,
  PulseConfigurations
>(
  PULSE_FEATURE_NAME,
  PulseConfigurationKeys.ToggleDiscountLabelByWsp,
  pulseConfigurationDefaultValues[
    PulseConfigurationKeys.ToggleDiscountLabelByWsp
  ],
);

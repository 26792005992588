import { ProductTypeString } from '@epicuro-next/data/baskets-dto';
import { RecommendationsPanelsConfig } from '@epicuro-next/models/recommendation-panels';
import { BasketRowsGroupType } from 'graphql-api/baskets-api-interfaces';

import { BasketNotificationStyle } from './types/basket-notification-style';
import {
  ProductImageMissingVisualConfig,
  UpdateTitleOptionsModel,
} from './types/product-image-missing-visual-config';

export const PULSE_FEATURE_NAME = 'pulse';

export enum PulseConfigurationKeys {
  AttributesPriorityConfig = 'attributes-priority-config',
  AccountAddressReverted = 'accountAddressReverted',
  AdvancedMDOAvailable = 'advanced-mdo-available',
  AdvancedMDOSizesAvailable = 'advanced-mdo-sizes-available',
  AllRulesFetchAtOnce = 'all-rules-fetch-at-once',
  BannerEnabled = 'banner-enabled',
  BannerLinkEnabled = 'banner-link-enabled',
  BasketNotificationStyle = 'basket-notification-style',
  CMIEnabled = 'cmi-enabled',
  ContactsUserMenu = 'contacts-user-menu',
  CustomerProductIdentifiersEnabled = 'customer-product-identifiers',
  DarkModeEnabled = 'dark-mode-enabled',
  DefaultCBasketGrouping = 'default-cbasket-grouping',
  DeliveryShowAccountId = 'delivery-show-account-id',
  DisabledImagesFormats = 'disabled-images-formats',
  DisabledShareBrowserApi = 'disabled-share-browser-api',
  discountFormat = 'discount-format',
  DocumentControlCommercialListBlacklist = 'document-control-commercial-list-blacklist',
  DocumentControlEnabled = 'document-control-enabled',
  EnableOrderBookV2 = 'enable-order-book-v2',
  EnableSearchV2 = 'enable-search-v2',
  EnableCatalogOnlyMode = 'enable-catalog-only-mode',
  EnableUseragreementButtonsAtEOD = 'enable-useragreement-buttons-at-eod',
  EventHubTracking = 'event-hub-tracking',
  EventTrackMapping = 'event-tracking-mapping',
  GTMEcommerceBasketProductItemMapper = 'gtm-ecommerce-basket-product-item-mapper',
  GTMEcommerceCheckBasketActionFieldMapper = 'gtm-ecommerce-check-basket-action-field-mapper',
  GTMEcommerceEventsEnabled = 'gtm-ecommerce-events-enabled',
  GTMEcommerceEventsMap = 'gtm-ecommerce-events-map',
  GTMEcommercePurchaseActionFieldMapper = 'gtm-ecommerce-purchase-action-field-mapper',
  GTMEcommercePurchaseExtrasMapper = 'gtm-ecommerce-purchase-extras-mapper',
  GTMEcommerceRegularProductItemMapper = 'gtm-ecommerce-regular-product-item-mapper',
  GTMEcommerceTrackCheckBasketOnFailure = 'gtm-ecommerce-track-check-basket-on-failure',
  GTMEcommerceUseInternalIds = 'gtm-ecommerce-use-internal-ids',
  HighlightFutureTypeLinesEnabled = 'highlight-future-type-lines-enabled',
  InvoicesPageEnabled = 'invoices-page-enabled',
  PopularityIcon = 'isPopularityIconShown',
  PowerListEnabled = 'power-list-enabled',
  PreventVisualSearchInEdge = 'prevent-visual-search-in-edge',
  PreventZoomByPixelRatio = 'prevent-zoom-by-pixel-ratio',
  ProductIdAdditionalPrefix = 'products:AdditionalPrefix',
  ProductImageMissingVisual = 'product-image-missing-visual',
  ProductTitleFormat = 'product-title',
  ProductTitleLevel = 'products:ProductTitleLevel',
  RecommendationsPanels = 'recommendations-panels',
  SearchSuggestEnabled = 'search-suggest-enabled',
  SecondaryNavigationBarMaxLinks = 'secondary-navigation-bar-max-links',
  SizePreviewV2Enabled = 'size-preview-v2-enabled',
  ShowMarkup = 'show-markup',
  ShowMaterialWidthIds = 'show-material-width-ids',
  ShowWholeSalePricesSettingsDefaultValue = 'show-wsp-price-setting-default-value',
  ShowAccountInHeader = 'show-account-in-header',
  ShowAccountTooltipInHeader = 'show-account-tooltip-in-header',
  SubscribeToAllEmails = 'subscribe-to-all-emails',
  TermsConditionsUserconsent = 'terms-conditions-userconsent',
  TrackingHostEventHubName = 'tracking-host-event-hub-name',
  TrackingHostNamespace = 'tracking-host-namespace',
  Variations = 'showVariationsInProductBasket',
  VersionPopupTracking = 'version-popup-tracking',
  VMIEnabled = 'vmi-enabled',
  UpdateTitleOptions = 'update-title-options',
  UseHeartIconForBookmark = 'use-heart-icon-for-bookmark',
  GcrNormalizePriceForPurchaseEvent = 'gcr-normalize-price-for-purchase-event',
  RecommendationSlotsFromMerchandiser = 'recommendation-slots-from-merchandiser',
  QuickSearchDropdown = 'quick-search-dropdown',
  UserMenuItemsBadges = 'user-menu-items-badges',
  ProductLabelAttributeKeys = 'product-label-attribute-keys',
  EnableHorizontalView = 'enable-horizontal-view',
  EnableFiltersVisible = 'enable-filters-visible',
  ToggleDiscountLabelByWsp = 'toggle-discount-label-by-wsp',
}

export interface PulseConfigurations {
  [PulseConfigurationKeys.AttributesPriorityConfig]: string;
  [PulseConfigurationKeys.AccountAddressReverted]: boolean;
  [PulseConfigurationKeys.AdvancedMDOAvailable]: boolean;
  [PulseConfigurationKeys.AdvancedMDOSizesAvailable]: boolean;
  [PulseConfigurationKeys.AllRulesFetchAtOnce]: boolean;
  [PulseConfigurationKeys.BannerEnabled]: boolean;
  [PulseConfigurationKeys.BannerLinkEnabled]: boolean;
  [PulseConfigurationKeys.BasketNotificationStyle]: BasketNotificationStyle;
  [PulseConfigurationKeys.CMIEnabled]: boolean;
  [PulseConfigurationKeys.ContactsUserMenu]: boolean;
  [PulseConfigurationKeys.CustomerProductIdentifiersEnabled]: boolean;
  [PulseConfigurationKeys.DarkModeEnabled]: boolean;
  [PulseConfigurationKeys.DefaultCBasketGrouping]: BasketRowsGroupType;
  [PulseConfigurationKeys.DeliveryShowAccountId]: boolean;
  [PulseConfigurationKeys.DisabledImagesFormats]: string[];
  [PulseConfigurationKeys.DisabledShareBrowserApi]: boolean;
  [PulseConfigurationKeys.discountFormat]: string;
  [PulseConfigurationKeys.DocumentControlCommercialListBlacklist]: string[];
  [PulseConfigurationKeys.DocumentControlEnabled]: boolean;
  [PulseConfigurationKeys.EnableOrderBookV2]: boolean;
  [PulseConfigurationKeys.EnableCatalogOnlyMode]: boolean;
  [PulseConfigurationKeys.EnableSearchV2]: boolean;
  [PulseConfigurationKeys.EnableUseragreementButtonsAtEOD]: boolean;
  [PulseConfigurationKeys.EventHubTracking]: boolean;
  [PulseConfigurationKeys.EventTrackMapping]: string;
  [PulseConfigurationKeys.GTMEcommerceBasketProductItemMapper]: string;
  [PulseConfigurationKeys.GTMEcommerceCheckBasketActionFieldMapper]: string;
  [PulseConfigurationKeys.GTMEcommerceEventsEnabled]: boolean;
  [PulseConfigurationKeys.GTMEcommerceEventsMap]: string;
  [PulseConfigurationKeys.GTMEcommercePurchaseActionFieldMapper]: string;
  [PulseConfigurationKeys.GTMEcommercePurchaseExtrasMapper]: string;
  [PulseConfigurationKeys.GTMEcommerceRegularProductItemMapper]: string;
  [PulseConfigurationKeys.GTMEcommerceTrackCheckBasketOnFailure]: boolean;
  [PulseConfigurationKeys.GTMEcommerceUseInternalIds]: boolean;
  [PulseConfigurationKeys.HighlightFutureTypeLinesEnabled]: boolean;
  [PulseConfigurationKeys.InvoicesPageEnabled]: boolean;
  [PulseConfigurationKeys.PopularityIcon]: boolean;
  [PulseConfigurationKeys.PowerListEnabled]: boolean;
  [PulseConfigurationKeys.PreventVisualSearchInEdge]: boolean;
  [PulseConfigurationKeys.PreventZoomByPixelRatio]: boolean;
  [PulseConfigurationKeys.ProductIdAdditionalPrefix]: string;
  [PulseConfigurationKeys.ProductImageMissingVisual]: string;
  [PulseConfigurationKeys.ProductTitleFormat]: string[];
  [PulseConfigurationKeys.ProductTitleLevel]: ProductTypeString;
  [PulseConfigurationKeys.RecommendationsPanels]: string;
  [PulseConfigurationKeys.SearchSuggestEnabled]: boolean;
  [PulseConfigurationKeys.SecondaryNavigationBarMaxLinks]: number;
  [PulseConfigurationKeys.SizePreviewV2Enabled]: boolean;
  [PulseConfigurationKeys.ShowMarkup]: boolean;
  [PulseConfigurationKeys.ShowMaterialWidthIds]: boolean;
  [PulseConfigurationKeys.ShowWholeSalePricesSettingsDefaultValue]: boolean;
  [PulseConfigurationKeys.ShowAccountInHeader]: boolean;
  [PulseConfigurationKeys.ShowAccountTooltipInHeader]: boolean;
  [PulseConfigurationKeys.SubscribeToAllEmails]: boolean;
  [PulseConfigurationKeys.TermsConditionsUserconsent]: boolean;
  [PulseConfigurationKeys.TrackingHostEventHubName]: string;
  [PulseConfigurationKeys.TrackingHostNamespace]: string | boolean;
  [PulseConfigurationKeys.Variations]: boolean;
  [PulseConfigurationKeys.VersionPopupTracking]: boolean;
  [PulseConfigurationKeys.VMIEnabled]: boolean;
  [PulseConfigurationKeys.UpdateTitleOptions]: string;
  [PulseConfigurationKeys.UseHeartIconForBookmark]: boolean;
  [PulseConfigurationKeys.GcrNormalizePriceForPurchaseEvent]: string;
  [PulseConfigurationKeys.RecommendationSlotsFromMerchandiser]: string[];
  [PulseConfigurationKeys.QuickSearchDropdown]: boolean;
  [PulseConfigurationKeys.UserMenuItemsBadges]: string;
  [PulseConfigurationKeys.ProductLabelAttributeKeys]: string[];
  [PulseConfigurationKeys.EnableHorizontalView]: boolean;
  [PulseConfigurationKeys.EnableFiltersVisible]: boolean;
  [PulseConfigurationKeys.ToggleDiscountLabelByWsp]: boolean;
}

export const defaultProductImageMissingVisualConfig: ProductImageMissingVisualConfig =
  {
    enabled: false,
  };

export const defaultRecommendationsPanelsConfig: RecommendationsPanelsConfig = {
  AddedToBasketNotification: [],
  AddedToBasketNotificationPane: [],
  BelowCBasket: [],
  BelowConfigStrip: [],
  Checkout: [],
  Checkout2: [],
  Spotlight: [],
  PBasket: [],
  SearchBar: [],
  SearchSuggestBar: [],
  SearchProducts: [],
};

export const defaultTitleOptionsConfig: UpdateTitleOptionsModel = {
  titleOptions: {
    cBasket: false,
    configStrip: false,
    search: false,
  },
};

export const pulseConfigurationDefaultValues = {
  [PulseConfigurationKeys.AttributesPriorityConfig]: '{}',
  [PulseConfigurationKeys.AccountAddressReverted]: true,
  [PulseConfigurationKeys.AdvancedMDOAvailable]: false,
  [PulseConfigurationKeys.AdvancedMDOSizesAvailable]: false,
  [PulseConfigurationKeys.AllRulesFetchAtOnce]: false,
  [PulseConfigurationKeys.BasketNotificationStyle]:
    BasketNotificationStyle.default,
  [PulseConfigurationKeys.CMIEnabled]: false,
  [PulseConfigurationKeys.ContactsUserMenu]: false,
  [PulseConfigurationKeys.DeliveryShowAccountId]: false,
  [PulseConfigurationKeys.DisabledShareBrowserApi]: false,
  [PulseConfigurationKeys.discountFormat]: '1.0-0',
  [PulseConfigurationKeys.DocumentControlCommercialListBlacklist]: [],
  [PulseConfigurationKeys.EnableOrderBookV2]: false,
  [PulseConfigurationKeys.EnableCatalogOnlyMode]: false,
  [PulseConfigurationKeys.EnableSearchV2]: false,
  [PulseConfigurationKeys.EnableUseragreementButtonsAtEOD]: false,
  [PulseConfigurationKeys.EventTrackMapping]: '{}',
  [PulseConfigurationKeys.HighlightFutureTypeLinesEnabled]: false,
  [PulseConfigurationKeys.InvoicesPageEnabled]: false,
  [PulseConfigurationKeys.PowerListEnabled]: false,
  [PulseConfigurationKeys.PreventVisualSearchInEdge]: false,
  [PulseConfigurationKeys.PreventZoomByPixelRatio]: false,
  [PulseConfigurationKeys.ProductIdAdditionalPrefix]: '',
  [PulseConfigurationKeys.ProductImageMissingVisual]: JSON.stringify(
    defaultProductImageMissingVisualConfig,
  ),
  [PulseConfigurationKeys.RecommendationsPanels]: JSON.stringify(
    defaultRecommendationsPanelsConfig,
  ),
  [PulseConfigurationKeys.SearchSuggestEnabled]: false,
  [PulseConfigurationKeys.ShowMarkup]: false,
  [PulseConfigurationKeys.SizePreviewV2Enabled]: true,
  [PulseConfigurationKeys.ShowMaterialWidthIds]: false,
  [PulseConfigurationKeys.ShowWholeSalePricesSettingsDefaultValue]: true,
  [PulseConfigurationKeys.ShowAccountInHeader]: false,
  [PulseConfigurationKeys.ShowAccountTooltipInHeader]: false,
  [PulseConfigurationKeys.SubscribeToAllEmails]: false,
  [PulseConfigurationKeys.TermsConditionsUserconsent]: false,
  [PulseConfigurationKeys.VersionPopupTracking]: false,
  [PulseConfigurationKeys.VMIEnabled]: false,
  [PulseConfigurationKeys.UpdateTitleOptions]: JSON.stringify(
    defaultTitleOptionsConfig,
  ),
  [PulseConfigurationKeys.UseHeartIconForBookmark]: true,
  [PulseConfigurationKeys.GcrNormalizePriceForPurchaseEvent]: '',
  [PulseConfigurationKeys.RecommendationSlotsFromMerchandiser]: [],
  [PulseConfigurationKeys.QuickSearchDropdown]: false,
  [PulseConfigurationKeys.UserMenuItemsBadges]: '{}',
  [PulseConfigurationKeys.ProductLabelAttributeKeys]: [],
  [PulseConfigurationKeys.EnableHorizontalView]: false,
  [PulseConfigurationKeys.EnableFiltersVisible]: false,
  [PulseConfigurationKeys.ToggleDiscountLabelByWsp]: false,
};
